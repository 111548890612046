import D from "react";
function S(a) {
  return a && a.__esModule && Object.prototype.hasOwnProperty.call(a, "default") ? a.default : a;
}
var P = { exports: {} }, b = {};
b._ = b._interop_require_default = T;
function T(a) {
  return a && a.__esModule ? a : { default: a };
}
function k(a) {
  throw new Error('Could not dynamically require "' + a + '". Please configure the dynamicRequireTargets or/and ignoreDynamicRequires option of @rollup/plugin-commonjs appropriately for this require call to work.');
}
var C = {}, q = {}, I;
function M() {
  return I || (I = 1, function(a) {
    "use client";
    Object.defineProperty(a, "__esModule", {
      value: !0
    }), Object.defineProperty(a, "LoadableContext", {
      enumerable: !0,
      get: function() {
        return _;
      }
    });
    const _ = (/* @__PURE__ */ b._(D)).default.createContext(null);
    process.env.NODE_ENV !== "production" && (_.displayName = "LoadableContext");
  }(q)), q;
}
var O;
function N() {
  return O || (O = 1, function(a) {
    Object.defineProperty(a, "__esModule", {
      value: !0
    }), Object.defineProperty(a, "default", {
      enumerable: !0,
      get: function() {
        return l;
      }
    });
    const c = /* @__PURE__ */ b._(D), _ = M();
    function m(t) {
      return t && t.default ? t.default : t;
    }
    const g = [], w = [];
    let h = !1;
    function v(t) {
      let r = t(), e = {
        loading: !0,
        loaded: null,
        error: null
      };
      return e.promise = r.then((n) => (e.loading = !1, e.loaded = n, n)).catch((n) => {
        throw e.loading = !1, e.error = n, n;
      }), e;
    }
    function L(t, r) {
      let e = Object.assign({
        loader: null,
        loading: null,
        delay: 200,
        timeout: null,
        webpack: null,
        modules: null
      }, r), n = null;
      function p() {
        if (!n) {
          const o = new u(t, e);
          n = {
            getCurrentValue: o.getCurrentValue.bind(o),
            subscribe: o.subscribe.bind(o),
            retry: o.retry.bind(o),
            promise: o.promise.bind(o)
          };
        }
        return n.promise();
      }
      if (typeof window > "u" && g.push(p), !h && typeof window < "u") {
        const o = e.webpack && typeof k.resolveWeak == "function" ? e.webpack() : e.modules;
        o && w.push((y) => {
          for (const f of o)
            if (y.includes(f))
              return p();
        });
      }
      function R() {
        p();
        const o = c.default.useContext(_.LoadableContext);
        o && Array.isArray(e.modules) && e.modules.forEach((y) => {
          o(y);
        });
      }
      function E(o, y) {
        R();
        const f = c.default.useSyncExternalStore(n.subscribe, n.getCurrentValue, n.getCurrentValue);
        return c.default.useImperativeHandle(y, () => ({
          retry: n.retry
        }), []), c.default.useMemo(() => f.loading || f.error ? /* @__PURE__ */ c.default.createElement(e.loading, {
          isLoading: f.loading,
          pastDelay: f.pastDelay,
          timedOut: f.timedOut,
          error: f.error,
          retry: n.retry
        }) : f.loaded ? /* @__PURE__ */ c.default.createElement(m(f.loaded), o) : null, [
          o,
          f
        ]);
      }
      return E.preload = () => p(), E.displayName = "LoadableComponent", /* @__PURE__ */ c.default.forwardRef(E);
    }
    class u {
      promise() {
        return this._res.promise;
      }
      retry() {
        this._clearTimeouts(), this._res = this._loadFn(this._opts.loader), this._state = {
          pastDelay: !1,
          timedOut: !1
        };
        const { _res: r, _opts: e } = this;
        r.loading && (typeof e.delay == "number" && (e.delay === 0 ? this._state.pastDelay = !0 : this._delay = setTimeout(() => {
          this._update({
            pastDelay: !0
          });
        }, e.delay)), typeof e.timeout == "number" && (this._timeout = setTimeout(() => {
          this._update({
            timedOut: !0
          });
        }, e.timeout))), this._res.promise.then(() => {
          this._update({}), this._clearTimeouts();
        }).catch((n) => {
          this._update({}), this._clearTimeouts();
        }), this._update({});
      }
      _update(r) {
        this._state = {
          ...this._state,
          error: this._res.error,
          loaded: this._res.loaded,
          loading: this._res.loading,
          ...r
        }, this._callbacks.forEach((e) => e());
      }
      _clearTimeouts() {
        clearTimeout(this._delay), clearTimeout(this._timeout);
      }
      getCurrentValue() {
        return this._state;
      }
      subscribe(r) {
        return this._callbacks.add(r), () => {
          this._callbacks.delete(r);
        };
      }
      constructor(r, e) {
        this._loadFn = r, this._opts = e, this._callbacks = /* @__PURE__ */ new Set(), this._delay = null, this._timeout = null, this.retry();
      }
    }
    function i(t) {
      return L(v, t);
    }
    function s(t, r) {
      let e = [];
      for (; t.length; ) {
        let n = t.pop();
        e.push(n(r));
      }
      return Promise.all(e).then(() => {
        if (t.length)
          return s(t, r);
      });
    }
    i.preloadAll = () => new Promise((t, r) => {
      s(g).then(t, r);
    }), i.preloadReady = (t) => (t === void 0 && (t = []), new Promise((r) => {
      const e = () => (h = !0, r());
      s(w, t).then(e, e);
    })), typeof window < "u" && (window.__NEXT_PRELOADREADY = i.preloadReady);
    const l = i;
  }(C)), C;
}
(function(a, d) {
  Object.defineProperty(d, "__esModule", {
    value: !0
  });
  function c(u, i) {
    for (var s in i)
      Object.defineProperty(u, s, {
        enumerable: !0,
        get: i[s]
      });
  }
  c(d, {
    noSSR: function() {
      return v;
    },
    default: function() {
      return L;
    }
  });
  const _ = b, m = /* @__PURE__ */ _._(D), g = /* @__PURE__ */ _._(N()), w = typeof window > "u";
  function h(u) {
    return {
      default: (u == null ? void 0 : u.default) || u
    };
  }
  function v(u, i) {
    if (delete i.webpack, delete i.modules, !w)
      return u(i);
    const s = i.loading;
    return () => /* @__PURE__ */ m.default.createElement(s, {
      error: null,
      isLoading: !0,
      pastDelay: !1,
      timedOut: !1
    });
  }
  function L(u, i) {
    let s = g.default, l = {
      // A loading component is not required, so we default it
      loading: (e) => {
        let { error: n, isLoading: p, pastDelay: R } = e;
        if (!R)
          return null;
        if (process.env.NODE_ENV !== "production") {
          if (p)
            return null;
          if (n)
            return /* @__PURE__ */ m.default.createElement("p", null, n.message, /* @__PURE__ */ m.default.createElement("br", null), n.stack);
        }
        return null;
      }
    };
    u instanceof Promise ? l.loader = () => u : typeof u == "function" ? l.loader = u : typeof u == "object" && (l = {
      ...l,
      ...u
    }), l = {
      ...l,
      ...i
    };
    const t = l.loader, r = () => t != null ? t().then(h) : Promise.resolve(h(() => null));
    return l.loadableGenerated && (l = {
      ...l,
      ...l.loadableGenerated
    }, delete l.loadableGenerated), typeof l.ssr == "boolean" && !l.ssr ? (delete l.webpack, delete l.modules, v(s, l)) : s({
      ...l,
      loader: r
    });
  }
  (typeof d.default == "function" || typeof d.default == "object" && d.default !== null) && typeof d.default.__esModule > "u" && (Object.defineProperty(d.default, "__esModule", { value: !0 }), Object.assign(d.default, d), a.exports = d.default);
})(P, P.exports);
var j = P.exports, A = j;
const V = /* @__PURE__ */ S(A), F = V(
  async () => (await import("@p5-wrapper/react")).ReactP5Wrapper,
  { ssr: !1 }
);
export {
  F as NextReactP5Wrapper
};
